import React, { Component } from 'react';
import './Home.css';
import BigNumber from "bignumber.js";
import Web3 from 'web3';
import PEEP$ from '../truffle_abis/PEEP$ ABI.json';
import IUniswapV2Pair from '../truffle_abis/IUniswapV2Pair.json';
import { truncateText } from "../utils/uiUtils"
import './peeps.png';



class Home extends Component {


    constructor(props) {
        super(props);

        this.state = {
            peepsContractAddress: "0x5919dea604631016c15c805e3d948a0384879892",
            testnetworkAddress: "0x61654897cE3efA84d024b86adf2BF5841B39F581", //Goerli
            wethPeepsPairAddress: "0xFdD0F179Ae8f71e55A5AA5c39b336DDA63FB17D7",
            wethUSDTPair: "0x0d4a11d5EEaaC28EC3F61d100daF4d40471f1852",
            userBalance: 0,
            userUSDTBalance: 0,
            peepsPerUSDT: 0,
            userWalletAddress: null,
            USDTPerPeeps: 0
        } 
        

    }

    componentDidMount = async () => {
        this.findUserSupply();
    }


    findUserSupply = async () => {
        if (window.ethereum) {
            window.web3 = new Web3(window.ethereum);
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const networkId = await window.web3.eth.net.getId();
            try {
                if (networkId !== 1) { //goerli 5, eth mainnet 1
                    alert("Please connect to the Ethereum Network in Metamask");
                } else {
                    const defaultNFTinfo = await new window.web3.eth.Contract(PEEP$.abi, this.state.peepsContractAddress);
                    let result = await defaultNFTinfo.methods.balanceOf(accounts[0]).call();
                    result = result / (10 ** 9);
                    console.log(result)  //AP
                    this.setState({userBalance : result});
                    this.setState({userWalletAddress : accounts[0]})
                    this.convertUSDToPeeps(window.web3);
                }
            } catch (e) {
                alert("Error with metamask");
                console.log(e);
            }
        }
    };


    disconnectWallet = async (e) => {
        e.preventDefault();
        this.setState({userWalletAddress : null})
    }





    donateToChairty = async (e, address) => {
        e.preventDefault();

        const formData = new FormData(e.target);
        const formProps = Object.fromEntries(formData);
        const charity = formProps['charity']; //depending on the chairty, that's what address is sent the Tokens



        if (window.ethereum) {
            window.web3 = new Web3(window.ethereum);
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const networkId = await window.web3.eth.net.getId();

            try {
                if (networkId !== 1) { //goerli 5, eth mainnet 1
                    alert("Please connect to the Ethereum Network in Metamask");
                } else {
                    const peepsContract = await new window.web3.eth.Contract(PEEP$.abi, this.state.testnetworkAddress);

                    const amount = new BigNumber(formProps.amount * 10 ** 18);
                    await peepsContract.methods.transfer(charity, amount).send({
                        from: accounts[0],
                        to: this.state.testnetworkAddress
                    });

                    alert("Successfully donated!");
                }
            } catch (e) {
                alert("Error with metamask");
                console.log(e);
            }
        }
    };

    convertUSDToPeeps = async (web3) => {
        let usdtperweth = await this.getWETHPrice(web3, this.state.wethUSDTPair);
        let PeepsPerWETH = await this.getPeepsPerWEth(web3, this.state.wethPeepsPairAddress);
        let peepsPerUSDT = PeepsPerWETH / usdtperweth;
        let USDTequiv = this.state.userBalance  / peepsPerUSDT;  //ap
        this.setState({USDTPerPeeps : this.state.userBalance / peepsPerUSDT}); // ap
        return peepsPerUSDT

    }




    getWETHPrice = async (web3, address) => {
        const defaultContract = await new web3.eth.Contract(IUniswapV2Pair.abi, address);
        let result = await defaultContract.methods.getReserves().call();
        return result[1] / result[0] * 10 ** 12
    }

    getPeepsPerWEth = async (web3, address) => {
        const defaultContract = await new web3.eth.Contract(IUniswapV2Pair.abi, address);
        let result = await defaultContract.methods.getReserves().call();
        return result[0] / result[1] * 10 ** 9
    }

    getUSDTPerPeeps = async (web3, address) => {
        const defaultContract = await new web3.eth.Contract(IUniswapV2Pair.abi, address);
        let result = await defaultContract.methods.getReserves().call();
        return result[1] / result[0] * 10 ** 6 

    }

    






    render() {
        return (

            <div className="page" style={{ textAlign: "center" }}>
                
                <div>
                    
                    <p id="newline"></p>
                    <p id="newline"></p>
                    <p id="newline"></p>
 

 
                  

                    <div id="walletAddressContainer">
                       {this.state.userWalletAddress && <p className="walletAddressContainer">{truncateText(this.state.userWalletAddress, 5, 5)}</p>}
                    
                       <center> Available PEEP$: {(this.state.userBalance.toFixed(0))} | Equivalent to: ${(this.state.USDTPerPeeps.toFixed(2))}</center>
                       <center>Open this page in your crypto wallet app.</center> 
                       <br></br>
                        
                    </div>

                    <div></div>
                </div>
                <div>




                    {
                        //(this.state.userBalance > 0) &&
                        <div>
                            <div>

                            <div className="peep"> Already have PEEP$ tokens? Great. </div>
                                    <div></div>
                                    <p id="new line"></p>
                                    {
                                        this.state.userWalletAddress == null &&
                                        <center><div><div className="connect" onClick={(e) => this.findUserSupply(e)}><div>Connect wallet</div></div></div></center>
                                    }
                                    {
                                        this.state.userWalletAddress !== null &&
                                        <center><div><div className="connect" onClick={(e) => this.disconnectWallet(e)}><div>Disconnect wallet</div></div></div></center>
                                    }
 

<br></br>
                            <div className="peep"> Need more PEEP$ tokens? Simple. </div>
                                    <p id="newline"></p>
                                    <center> <div className="btnp">
                                        <a href="https://thepeoplescoin.co/peeps-buy" target="_blank" rel="noopener noreferrer">Get PEEP$ here</a>
                                    </div> </center>
                                    <br />
                                    


                                    <div className="cause">Choose your cause below:</div>

                                <div>

                                </div>
                                <div></div>
                                <p id="newline"></p>
                                <form onSubmit={(e) => this.donateToChairty(e)}>
                                    <select name="charity" id="charity">
                                        <option value="0x0923f7F2fF73636e5E0668D3Af20D37069806cec">1.The People's Coin Community Fund</option>
                                        <option value="0xEb2d82d779bA4C33D449DF2162a98315523F5c3F">Alex's Lemonade Stand</option>
                                        <option value="0x8b862B940C242ad92C60C03b801f101b6A81626D">All Pets Veterinary Hospital</option>
                                        <option value="00xBcA358d5eF915a146F7cA30b071C831Bb64a1c57">American Cancer Society</option>
                                        <option value="0xE8164225c4cb2d599cbdfc2e9B8cA1014188F293">Andrea Bocelli Foundation</option>
                                        <option value="0xe06bc8A9B9AD3542211a5F163Ce9B53115990D61">ASPCA</option>
                                        <option value="0x896CaDA85313b535d5b37e0FCa71f700Ec18B92b">Blue Ribbon Project</option>
                                        <option value="0xaf5b4D898EeBAd01d0cFB7Da6AF16fbD0ba0DE92">Cystic Fibrosis Foundation</option>
                                        <option value="0xd64B08E5662d9A0EE1599eA47FcD6a7f35597E12">Gary Sinise Foundation</option>
                                        <option value="0xa2461c7F392Fa45115c80fa73C5A069188558a7f">Make A Wish Foundation</option>
                                        <option value="0xF346210f1e05f3aEcbeAEEB4Db63cD2aCe751E70">National MS Society</option>
                                        <option value="0x2dfb8979D863b3CfB44345ea374AfE1D0B00F0fE">Pandas Network</option>
                                        <option value="0x87b491dF689953CE123A1a297fbE539C2320e7F8">St. Jude Children's Research Hospital</option>
                                        <option value="0xdb1c5a32DcaaB00EBD0f92977bf703Ae71F7de0c">Tunnel to Towers Foundation</option>
                                        <option value="0x3C34619D9d8580A6406Fdb2006cDf4b8ce6c70B2">USO</option>
                                        <option value="0x88F801A3223dD55DE8E50EbB0057a639F858E554">Victory4Youth</option>
                                        <option value="0x246172cb3246EA86C3cB7eCcF0a14e6b4fD0D9e0">Water is Life</option>
                                        <option value="0xaF66f0756c133cB1e82DA0927C7e023937a6D9d8">Wounded Warrior Project</option>
                                    </select>
                                    <br />
                                    <div>
                                        <p id="newline"></p>
                                    </div>
                                    <center><input type='number' id='amount' name='amount' placeholder="Donation (in $USD)" required></input></center>
                                    <br />
                                    <div className="peep"> Need help or don't see your cause? </div>
                                    <p id="newline"></p>
                                    <center> <div className="btnp">
                                        <a href="https://thepeoplescoin.co/#contact" target="_blank" rel="noopener noreferrer">Contact us here</a>
                                    </div> </center>
                                    <br />
                                    
                                  
                                    <button onSubmit={(e) => this.donateToChairty(e)}>Submit Form</button>
                                </form>
                            </div>
                        </div>

                    }


                </div>
                <p id="newline"></p>

                <div></div>

            </div>


        )
    }
}
export default Home;