import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css'
import App from './components/App';
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux"
import { persistReducer, persistStore } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga"
import combinedReducer from "./reducers"
import * as serviceWorker from './serviceWorker';


const persistConfig = {
    key: "root",
    storage
}
const persistedReducer = persistReducer(persistConfig, combinedReducer)

const sagaMiddleware = createSagaMiddleware()

const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)))

persistStore(store)

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

