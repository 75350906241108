import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import './peeps.png';


class App extends React.Component {


    render() {
        return (
            <Router>
                <div className="App">
                    <Routes>
                        <Route exact path="/" element={<Home/>} />
                        <Route exact path="/*" element={<Home/>} />
                    </Routes>
                </div>
    
            </Router>
            

        )
        
    }
}

export default App;